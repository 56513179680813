<template>
  <div>
    <!--begin::Tab Content-->
    <div
      id="kt_apps_contacts_view_tab_1"
      class="tab-pane active"
      role="tabpanel"
    >
      <div class="container">
        <form class="form">
          <div class="form-group">
            <div class="col-lg-6 pt-5 pb-5">
              <b-form-select
                v-model="type"
                :options="typeOptions"
              ></b-form-select>
            </div>
            <textarea
              id="exampleTextarea"
              v-model="note"
              class="form-control form-control-lg form-control-solid"
              rows="3"
              placeholder="Type notes"
            ></textarea>
          </div>
          <div class="row">
            <div class="col">
              <a class="btn btn-light-primary font-weight-bold" @click="addNote"
                >Add notes</a
              >
              <a href="#" class="btn btn-clean font-weight-bold">Cancel</a>
            </div>
          </div>
        </form>
        <div class="separator separator-dashed my-10"></div>
        <!--begin::Timeline-->

        <v-timeline align-top dense>
          <v-timeline-item
            v-for="(note, index) in noteList"
            :key="index"
            icon="mdi-note"
            fill-dot
            large
          >
            <div class="mr-2">
              <a
                href="#"
                class="text-dark-75 text-hover-primary font-weight-bold"
                >{{ note.type }}</a
              >
              <span class="text-muted ml-2">{{
                note.updated_at | date_format
              }}</span>
            </div>
            <p class="p-0">{{ note.note }}</p>
          </v-timeline-item>
        </v-timeline>

        <!--end::Timeline-->
        <div class="row mt-6">
          <v-pagination
            v-model="currentPage"
            :total-rows="noteListCount"
            :per-page="limit"
            style="margin: 0 auto;"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!--end::Tab Content-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions } from "vuex";
import PartnerNotes from "@/graphql/queries/PartnerNotes.gql";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  data() {
    return {
      currentPage: 1,
      noteListCount: 0,
      limit: 5,
      noteList: [],
      type: "Phone Record",
      typeOptions: [
        { value: "Phone Record", text: "Phone Record" },
        { value: "Sales Note", text: "Sales Note" },
        { value: "Credit", text: "Credit" },
        { value: "Performance Related", text: "Performance Related" },
        { value: "Other", text: "Other" },
      ],
      note: "",
    };
  },
  apollo: {
    noteList: {
      query: PartnerNotes,
      variables() {
        return {
          supplier_company: this.$store.state.auth.user.company_relation,
          seller_company: this.$route.params.id,
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        };
      },
    },
  },

  mounted() {
    setTimeout(() => {
      this.getTotalCount();

      this.$apollo.queries.noteList.refetch();
    }, 1000);
  },
  methods: {
    ...mapActions(["submitNote", "getNoteTotalCount"]),
    getTotalCount() {
      this.getNoteTotalCount({
        params:
          "supplier_company=" +
          this.$store.state.auth.user.company_relation +
          "&seller_company=" +
          this.$route.params.id,
      }).then((res) => {
        this.noteListCount = res.data;
      });
    },
    addNote() {
      if (this.note == "") {
        this.$bvToast.toast("Empty Note.", {
          title: "Error",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
        });
        return;
      }
      this.submitNote({
        supplier_company: this.$store.state.auth.user.company_relation,
        seller_company: this.$route.params.id,
        type: this.type,
        note: this.note,
        created_user: this.$store.state.auth.user.id,
      })
        .then(() => {
          Swal.fire({
            title: "",
            text: "The application has been successfully updated!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
          this.getTotalCount();
          this.$apollo.queries.noteList.refetch();
          this.note = "";
        })
        .catch(() => {
          this.$bvToast.toast("An Error Occured!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
        });
    },
  },
};
</script>
