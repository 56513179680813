<template>
  <div>
    <!-- Import Recent 10 orders from  Marketplace Company account.  -->

    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder font-size-h3 text-dark"
            >Recent Orders</span
          >
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link to="/market/order-list" class="btn  font-weight-bolder">
            <span class="svg-icon svg-icon-md">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/Flatten.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="9" cy="15" r="6"></circle>
                  <path
                    d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                    fill="#000000"
                    opacity="0.3"
                  ></path>
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
            View all orders
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <v-data-table
        v-if="$apollo.data.orders"
        :headers="headers"
        :items="orders"
        hide-default-footer
      >
        <template #[`item.identifier`]="{ item }">
          <router-link :to="'/market/order-detail/' + item.identifier">
            {{ item.identifier }}
          </router-link>
        </template>
        <template #[`item.shipTo`]="{ item }">
          <div v-if="item.shipToAddress">
            {{
              (item.shipToAddress.first_name || "") +
                " " +
                (item.last_name || "")
            }}
          </div>
        </template>
        <template #[`item.historicalStatus`]="{ item }">
          {{ item.historicalStatus | currentStatus }}
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ item.created_at | date_format }}
        </template>
        <template #[`item.total`]="{ item }">
          {{ item.total | dollar_format }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import GetOrdersForVendorAndBuyer from "@/graphql/queries/GetOrdersForVendorAndBuyer.gql";
import moment from "moment";

export default {
  filters: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map((status) => ({ ...status }));
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date);
      });
      return sorted.length > 0 ? sorted[sorted.length - 1].status : "";
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: function() {
    return {
      headers: [
        { text: "Order", value: "identifier" },
        {
          text: "Ship to",
          value: "shipTo",
        },
        { text: "Date Created", value: "created_at" },
        { text: "Amount", value: "total" },
        { text: "Status", value: "historicalStatus" },
        // { text: "Action", value: "iron" }
      ],
    };
  },
  apollo: {
    orders: {
      query: GetOrdersForVendorAndBuyer,
      variables() {
        return {
          vendorCompany: this.$store.state.auth.user.company_relation,
          buyerCompany: this.$route.params.id,
        };
      },
    },
  },
};
</script>
