<template>
  <div style="background:#e4e6f1;">
    <div v-if="crms[0] != undefined" class="container">
      <div class="row ">
        <div class="col-lg-5">
          <div class="display-4">
            {{ crms[0].seller_company.business_profile.company_name }}
          </div>
        </div>
        <div class="col-lg-7">
          <div class="text-lg-right">
            <v-btn
              elevation="9"
              rounded
              color="grey"
              @click="$router.push('/crm/partners')"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>

            <v-btn
              elevation="9"
              rounded
              color="primary"
              @click="$router.push('/crm/partner-edit/' + crms[0].id)"
            >
              <v-icon left>
                mdi-package
              </v-icon>
              Edit Details
            </v-btn>
          </div>
        </div>
      </div>

      <div class="row " style="background:#e4e6f1;">
        <div class="col-lg-4">
          <v-card class="" max-width="344">
            <div class="d-flex justify-content-center">
              <v-img
                maxheight="150"
                max-width="150"
                class="mb-3"
                :src="
                  crms[0].seller_company.business_profile.logo != null
                    ? baseurl + crms[0].seller_company.business_profile.logo.url
                    : '/media/bike1.jpg'
                "
              ></v-img>
            </div>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-row align="center" class=""> </v-row>

                  <div class=" mt-14">
                    <div>
                      {{
                        crms[0].seller_company.business_profile.type
                          | company_type
                      }}
                    </div>
                    <div class="text-dark">
                      <span class="svg-icon svg-icon-md text-white mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z"
                              fill="#111"
                            ></path>
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                        <!-- FILLED from  Biz Profile collection --> </span
                      >{{ crms[0].seller_company.business_profile.city }},
                      {{ crms[0].seller_company.business_profile.state }}
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="80"> </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <a
                class="btn btn-sm btn-light font-weight-bolder text-uppercase mr-3"
                @click="$router.push('/crm/partner-edit/' + crms[0].id)"
                >Edit Company Info</a
              >
            </v-card-actions>

            <v-card-text>
              <div class="display-2 font-weight-light"></div>

              <div class="row">
                <div class="col-12">
                  <div class="subtitle-1 font-weight-light">
                    <i
                      v-if="crms[0].seller_company.badge == 'verified'"
                      class="flaticon2-correct text-success icon-md ml-2"
                    ></i>
                    <i
                      v-if="crms[0].seller_company.badge == 'non_verified'"
                      class="flaticon2-information text-success icon-md ml-2"
                    ></i>
                    <i
                      v-if="crms[0].seller_company.badge == 'new'"
                      class="flaticon2-user-outline-symbol text-success icon-md ml-2"
                    ></i>
                    <i
                      v-if="crms[0].seller_company.badge == 'premier'"
                      class="flaticon2-cup text-success icon-md ml-2"
                    ></i>
                    {{ crms[0].seller_company.badge }}

                    <i>{{ crms[0].partner_status }} Partner </i>
                  </div>
                </div>
              </div>

              <v-list two-line subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    <v-list-item-title>{{
                      crms[0].seller_company.business_profile.phone
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        crms[0].seller_company.business_profile.email
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Website:</v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        crms[0].seller_company.business_profile.website
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-text>
              <span class="display-3 mb-4">Quick Overview</span>
              <div v-if="crm_quick_note" class="mt-4">
                {{ crms[0].crm_quick_note }}
              </div>
            </v-card-text>
          </v-card>

          <v-card class="mx-auto " max-width="374">
            <v-card-text>
              <span class="display-3">Sales Info</span>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <div class="card-body pt-2">
              <div class="form-group row my-2">
                <label class="col-5 col-form-label"
                  >Pricing List Assigned</label
                >
                <div class="col-7">
                  <span
                    v-if="crms[0].pricelist != null"
                    class="form-control-plaintext "
                  >
                    {{ crms[0].pricelist.listname }}
                  </span>
                </div>
              </div>

              <div class="form-group row my-2">
                <label class="col-5 col-form-label">Sales Rep:</label>
                <div class="col-7">
                  <span class="form-control-plaintext ">{{
                    crms[0].sales_rep
                  }}</span>
                </div>
              </div>
              <div class="form-group row my-2">
                <label class="col-5 col-form-label">Order Instructions</label>
                <div class="col-7">
                  <span class="form-control-plaintext ">{{
                    crms[0].custom_order_note
                  }}</span>
                </div>
              </div>

              <div class="form-group row my-2">
                <label class="col-5 col-form-label">Ship Instructions</label>
                <div class="col-7">
                  <span class="form-control-plaintext ">{{
                    crms[0].custom_shipping_note
                  }}</span>
                </div>
              </div>
            </div>
          </v-card>
          <v-card color="warning">
            <v-card-text>
              This action can not be undone and will remove application.
              <v-btn
                elevation="9"
                small
                rounded
                class=" mt-6  mr-2"
                @click="deleteApplication"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Delete Partner
              </v-btn>
            </v-card-text>
          </v-card>
        </div>

        <div class="col-lg-8">
          <v-card class="mt-4 mx-auto">
            <v-sheet
              class="v-sheet--offset mx-auto"
              color="cyan"
              elevation="12"
              max-width="calc(100% - 32px)"
            >
              <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="title font-weight-light mb-2">
                Monthly Sales Trends
              </div>
              <div class="subheading font-weight-light grey--text">
                Order Volumes
              </div>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small>
                mdi-clock
              </v-icon>
              <span class="caption grey--text font-weight-light"
                >last updated 30 minutes ago</span
              >
            </v-card-text>
          </v-card>

          <base-material-card class="px-5 py-3">
            <template #heading>
              <div
                class="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0"
              >
                <span class="font-weight-bold  ">Progress to Sales Goal</span>
                <template
                  v-if="
                    crms[0].ytd_sales == null ||
                      crms[0].ytd_sales == 0 ||
                      crms[0].yearly_sales_goal == null ||
                      crms[0].yearly_sales_goal == 0
                  "
                >
                  <div class="progress progress-xs mt-2 mb-2">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :style="'width: 0%;'"
                      aria-valuenow="90.66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="font-weight-bolder ">0%</span>
                </template>
                <template
                  v-else-if="crms[0].ytd_sales > crms[0].yearly_sales_goal"
                >
                  <div class="progress progress-xs mt-2 mb-2">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :style="'width: 100%;'"
                      aria-valuenow="90.66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="font-weight-bolder text-white"
                    >{{
                      parseInt(
                        (parseInt(crms[0].ytd_sales) /
                          parseInt(crms[0].yearly_sales_goal)) *
                          100
                      )
                    }}%</span
                  >
                </template>
                <template v-else>
                  <div class="progress progress-xs mt-2 mb-2">
                    <div
                      class="progress-bar bg-dark"
                      role="progressbar"
                      :style="
                        'width: ' +
                          parseInt(
                            (parseInt(crms[0].ytd_sales) /
                              parseInt(crms[0].yearly_sales_goal)) *
                              100
                          ) +
                          '%;'
                      "
                      aria-valuenow="90.66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="font-weight-bolder text-white"
                    >{{
                      parseInt(
                        (parseInt(crms[0].ytd_sales) /
                          parseInt(crms[0].yearly_sales_goal)) *
                          100
                      )
                    }}%</span
                  >
                </template>
              </div>
            </template>

            <div class="d-flex align-items-center flex-wrap ">
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-piggy-bank icon-2x  font-weight-bold"></i>
                </span>

                <!-- YTD sales should be field on CRM collection that will be populated by marketplace via API only. can use test value in field -->
                <div class="d-flex flex-column ">
                  <span class="font-weight-bolder font-size-sm">YTD Sales</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span
                    >{{ crms[0].ytd_sales | filter_money }}</span
                  >
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="mr-4">
                  <i class="flaticon-confetti icon-2x  font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column ">
                  <span class="font-weight-bolder font-size-sm"
                    >Sales Goal</span
                  >
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span
                    >{{ crms[0].yearly_sales_goal | filter_money }}</span
                  >
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <!-- Average order size to be field in CRM collection that is populated by API from marketplace -->
                <div class="d-flex flex-column ">
                  <span class="font-weight-bolder font-size-sm"
                    >Average Order Size</span
                  >
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-white font-weight-bold">$</span
                    >545.60</span
                  >
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <!-- Average order size to be field in CRM collection that is populated by API from marketplace -->
                <div class="d-flex flex-column ">
        
                </div>
              </div>
              <!--end: Item-->
            </div>
          </base-material-card>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="display-3 ">Recent Orders</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <partnerRecentOrders> </partnerRecentOrders>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="display-3 ">Contacts</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <partnerContactList> </partnerContactList>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="display-3 ">Sales Goals</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-card-text>
                    <div class="card-title">
                      <h3 class="card-label text-dark">Sales goals</h3>
                    </div>
                    <div class="card-toolbar"></div>

                    <div class="card-body pt-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <b-list-group>
                            <b-list-group-item
                              >January: ${{
                                crms[0].jan_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >Febuary: ${{
                                crms[0].feb_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >March: ${{
                                crms[0].mar_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >April: ${{
                                crms[0].apr_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >May: ${{
                                crms[0].may_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >June: ${{
                                crms[0].jun_goal | filter_money
                              }}</b-list-group-item
                            >
                          </b-list-group>
                        </div>
                        <div class="col-lg-6">
                          <b-list-group>
                            <b-list-group-item
                              >July: ${{
                                crms[0].jul_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >August: ${{
                                crms[0].aug_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >September: ${{
                                crms[0].sep_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >October: ${{
                                crms[0].oct_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >November: ${{
                                crms[0].nov_goal | filter_money
                              }}</b-list-group-item
                            >
                            <b-list-group-item
                              >December: ${{
                                crms[0].dec_goal | filter_money
                              }}</b-list-group-item
                            >
                          </b-list-group>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="display-3 ">Recent Messages</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ChatBox />
                <div class="pt-2"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-card>
            <partnerNotes> </partnerNotes>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerContactList from '../../../components/crm/Partner-contact-list.vue'
import partnerNotes from '../../../components/crm/Partner-notes.vue'
import partnerRecentOrders from '../../../components/crm/Partner-recent-orders.vue'
import { GetCrms } from '@/graphql/queries/GetCrms.gql'
import GetApplication from '@/graphql/queries/GetApplication.gql'
import DeleteCrm from '@/graphql/mutations/DeleteCrm.gql'
import DeleteApplication from '@/graphql/mutations/DeleteApplication.gql'
import GetPartnerList from '@/graphql/queries/GetPartnerList.gql'
import GetPartnerCard from '@/graphql/queries/GetPartnerCard.gql'
import GetCartItems from '@/graphql/queries/GetCartItems.gql'
import DeleteCartItem from '@/graphql/mutations/DeleteCartItem.gql'
import Swal from 'sweetalert2'

import moment from 'moment'
import ChatBox from '@/components/chat/ChatBox'

export default {
  components: {
    partnerContactList,
    partnerNotes,
    partnerRecentOrders,
    ChatBox,
  },
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('hh:mm A MM/DD/YYYY')
      }
    },
    company_type: function(value) {
      if (value == 'onlineonly') {
        return 'Online Only'
      } else if (value == 'localonline') {
        return 'Local & Online'
      } else if (value == 'brick') {
        return 'Brick and Mortar'
      }
    },

    filter_zero: function(value) {
      if (value == null || value == undefined) return '0.00'
      else return value
    },

    filter_money: function(value) {
      if (value == null || value == undefined) return '0.00'
      else return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
  },
  data() {
    return {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ],
      value: [0, 0, 0],
      tabIndex: null,
      tab: null,
      tabIndex2: 0,
      baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
      message: '',
      crms: [],
    }
  },
  apollo: {
    // chats: {
    //   query: gql`
    //     query ChatsQuery($supplier_id: Int!, $seller_id: Int!) {
    //       chats(supplier_id: $supplier_id, seller_id: $seller_id) {
    //         id
    //         seller_id
    //         supplier_id
    //         message
    //         date
    //         is_seller
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       supplier_id: parseInt(this.$store.state.auth.user.company_relation),
    //       seller_id: parseInt(this.$route.params.id),
    //     }
    //   },
    //   subscribeToMore: {
    //     document: gql`
    //       subscription MessageSentSubscription {
    //         messageSent {
    //           id
    //           seller_id
    //           supplier_id
    //           message
    //           date
    //           is_seller
    //         }
    //       }
    //     `,
    //     updateQuery: (previousData, { subscriptionData }) => {
    //       return {
    //         chats: [...previousData.chats, subscriptionData.data.messageSent],
    //       }
    //     },
    //   },
    //   client: 'chatClient',
    // },
    crms: {
      query: GetCrms,
      variables() {
        return {
          supplier_company: parseInt(
            this.$store.state.auth.user.company_relation
          ),
          seller_company: parseInt(this.$route.params.id),
        }
      },
    },
    applications: {
      query: GetApplication,
      variables() {
        return {
          supplier_company: parseInt(
            this.$store.state.auth.user.company_relation
          ),
          seller_company: parseInt(this.$route.params.id),
        }
      },
    },
  },

  watch: {
    chats() {
      setTimeout(() => {
        this.scrollToEnd()
      }, 500)
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollToEnd()
      this.$apollo.queries.crms.refetch()
    }, 1000)
  },
  methods: {
    scrollToEnd() {
      var chat_div = document.querySelector('#chat_div')
      if (chat_div !== null) {
        chat_div.scrollTop = chat_div.scrollHeight
      }
    },
    async sendMessage() {
      const message = this.message
      this.message = ''

      let res = await this.getCompanyInfo({
        id: parseInt(this.$route.params.id),
      })
      let emails = []
      for (let i in res.data.company_users) {
        if (res.data.company_users[i].get_notice == true) {
          emails.push(res.data.company_users[i].email)
        }
      }
      // let receiver_email = emails.join("-");

      if (message != '') {
        // await this.$apollo.mutate({
        //   client: 'chatClient',
        //   mutation: gql`
        //     mutation SendMessageMutation(
        //       $seller_id: Int!
        //       $supplier_id: Int!
        //       $message: String!
        //       $sender: String!
        //       $receiver_email: String!
        //     ) {
        //       sendMessage(
        //         seller_id: $seller_id
        //         supplier_id: $supplier_id
        //         message: $message
        //         is_seller: 0
        //         sender: $sender
        //         receiver_email: $receiver_email
        //       ) {
        //         id
        //         seller_id
        //         supplier_id
        //         message
        //         date
        //         is_seller
        //       }
        //     }
        //   `,
        //   variables: {
        //     supplier_id: parseInt(this.$store.state.auth.user.company_relation),
        //     seller_id: parseInt(this.$route.params.id),
        //     message,
        //     sender:
        //       this.$store.state.auth.user.FirstName +
        //       ' ' +
        //       this.$store.state.auth.user.LastName,
        //     receiver_email: receiver_email,
        //   },
        // })
        this.scrollToEnd()
      }
    },
    deleteApplication() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          title: 'Confirm',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value == true) {
            //async ecom data
            await this.$apollo.mutate({
              mutation: DeleteApplication,
              variables: {
                id: this.applications[0].id,
              },
            })
            const cartItems = await this.$apollo.query({
              query: GetCartItems,
              variables: {
                vendorId: this.$store.state.auth.user.company_relation,
                userId: this.$route.params.id,
              },
            })

            if (cartItems.data.cartItems.length > 0) {
              const cartItemsIds = cartItems.data.cartItems.map((cart) => {
                return cart.id
              })
              await Promise.all(
                cartItemsIds.map(async (id) => {
                  await this.deleteCartItems(id)
                })
              )
            }
            await this.$apollo.mutate({
              mutation: DeleteCrm,
              variables: {
                id: this.crms[0].id,
              },
              refetchQueries: [
                {
                  query: GetPartnerList,
                  variables: {
                    supplier_company: this.$store.state.auth.user
                      .company_relation,
                    limit: 10,
                    start: 0, // TODO use company id
                  },
                },
                {
                  query: GetPartnerCard,
                  variables: {
                    supplier_company: this.$store.state.auth.user
                      .company_relation,
                    limit: 10,
                    start: 0, // TODO use company id
                  },
                },
              ],
            })
            this.$router.go(-1)
            Swal.fire({
              title: '',
              text: 'The application has been successfully deleted!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
          }
        })
    },
    async deleteCartItems(id) {
      await this.$apollo.mutate({
        mutation: DeleteCartItem,
        variables: {
          id: id,
        },
      })
    },
  },
}
</script>
