var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header flex-wrap border-0 pt-6 pb-0"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{staticClass:"btn  font-weight-bolder",attrs:{"to":"/market/order-list"}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24px","height":"24px","viewBox":"0 0 24 24","version":"1.1"}},[_c('g',{attrs:{"stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"24","height":"24"}}),_c('circle',{attrs:{"fill":"#000000","cx":"9","cy":"15","r":"6"}}),_c('path',{attrs:{"d":"M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z","fill":"#000000","opacity":"0.3"}})])])]),_vm._v(" View all orders ")])],1)]),(_vm.$apollo.data.orders)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.identifier",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/market/order-detail/' + item.identifier}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.shipTo",fn:function(ref){
var item = ref.item;
return [(item.shipToAddress)?_c('div',[_vm._v(" "+_vm._s((item.shipToAddress.first_name || "") + " " + (item.last_name || ""))+" ")]):_vm._e()]}},{key:"item.historicalStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currentStatus")(item.historicalStatus))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date_format")(item.created_at))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]}}],null,true)}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder font-size-h3 text-dark"},[_vm._v("Recent Orders")])])}]

export { render, staticRenderFns }