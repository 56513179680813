<template>
  <div class="container">
    <!--begin::Header-->

    <!--end::Header-->
    <!--begin::Body-->

    <div class="display-3">Contacts</div>

    <v-row dense class="mt-4">
      <v-col v-for="(user, index) in users" :key="index" :cols="6">
        <v-card max-height="500" max-width="300" class="mx-auto mb-3 h-100">
          <v-img
            src="https://cdn.vuetifyjs.com/images/lists/ali.png"
            height="150px"
            dark
          >
            <v-row class="fill-height">
              <v-card-title> </v-card-title>

              <v-spacer></v-spacer>
              <div class="text-center">
                <v-card-title class="white--text pl-12 pt-12"> </v-card-title>
              </div>
            </v-row>
          </v-img>
          <div class="display-3  text-center">
            {{ user.FirstName }} {{ user.LastName }}
          </div>

          <div class="text-center">
            <div v-if="user.business_title != null">
              {{ user.business_title }}
            </div>

            <div v-else>Goose User</div>
          </div>

          <v-divider inset></v-divider>

          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <div v-if="user.contact_phone != null">
                    <a
                      v-if="user.show_contact_phone"
                      href="#"
                      class="text-dark text-hover-primary"
                    >
                      {{ user.contact_phone }}
                    </a>
                    <div v-else>Phone # Private</div>
                  </div>

                  <div v-else>Not Provided</div>
                </v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <div v-if="user.contact_email != null">
                    <a
                      v-if="user.show_contact_email"
                      href="#"
                      class="text-dark text-hover-primary"
                    >
                      {{ user.contact_email }}
                    </a>
                    <div v-else>Email Private</div>
                  </div>

                  <div v-else>Not Provided</div>
                </v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <!--begin::Title-->

        <!--end::Title-->
        <!--begin::Info-->

        <!--end::Info-->
      </v-col>
    </v-row>
    <!--end::Item-->

    <!--end::Body-->

    <!--begin::Card-->

    <!--end::Card-->
  </div>
</template>

<script>
import PartnerUsers from "@/graphql/queries/PartnerUsers.gql";

export default {
  data() {
    return {
      users: [],
    };
  },
  apollo: {
    users: {
      query: PartnerUsers,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style>
.md-card-profile .md-card-avatar {
  max-width: 130px;
  max-height: 130px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.md-card-profile .md-card-avatar,
.md-card-testimonial .md-card-avatar {
  max-width: 130px;
  max-height: 130px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
</style>
